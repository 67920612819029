import {ExpandMore} from '@mui/icons-material'
import {
  Accordion,
  accordionClasses,
  AccordionDetails,
  AccordionSummary,
  accordionSummaryClasses,
  Box,
  Grid,
  styled,
} from '@mui/material'
import {graphql} from 'gatsby'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import * as React from 'react'
import remark from 'remark'
import remarkGFM from 'remark-gfm'
import remarkHTML from 'remark-html'
import {ErrorMessage, Headline, Link, Section, SidebarGrid} from '../components'
import {CookieConsentProvider} from '../context'
import {PageLayout} from '../layout'
import {pxToRem} from '../theme'

export interface FAQPageProps {
  data?: {
    site: {siteMetadata: {title: string}}
    faq: {
      frontmatter: {
        categories: Array<{
          title: string
          questions: Array<{question: string; answer: string}>
        }>
      }
    }
    localizedSettings: {
      frontmatter: {
        phoneNumber: string
      }
    }
  }
  error?: Error
}

const StyledPageTitle = styled(Headline)(({theme}) => ({
  marginBottom: theme.spacing(6),
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(9),
  },
}))

const StyledCategoryContainer = styled(Box)(({theme}) => ({
  margin: theme.spacing(4, 0),
}))

const StyledCategoryTitle = styled(Headline)(({theme}) => ({
  marginBottom: theme.spacing(2),
}))

const StyledAccordion = styled(Accordion)(({theme}) => ({
  backgroundColor: 'transparent',
  '& + *': {
    borderTop: `1px solid ${theme.palette.text.primary}`,
  },
  '&:before': {
    display: 'none',
  },
  [`&.${accordionClasses.expanded}`]: {
    marginBottom: 0,
  },
}))

const StyledAccordionSummary = styled(AccordionSummary)(({theme}) => ({
  fontSize: pxToRem(18),
  fontStyle: 'italic',
  padding: theme.spacing(2, 0),
  color: theme.palette.text.primary,
  '& svg': {
    color: theme.palette.text.primary,
  },
  [`&.${accordionSummaryClasses.expanded}`]: {
    color: theme.palette.primary.main,
    '& svg': {
      color: theme.palette.primary.main,
    },
  },
}))

const StyledAccordionDetails = styled(AccordionDetails)(({theme}) => ({
  fontSize: pxToRem(14),
  flexDirection: 'column',
  paddingLeft: 0,
  '& > p:first-of-type': {
    marginTop: 0,
  },
  '& a': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

const FAQPage: React.FC<FAQPageProps> = ({error, data}) => {
  const {t} = useTranslation()
  const {language} = useI18next()

  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>
  }

  if (!data) {
    return (
      <ErrorMessage>
        Your application has no available content. Please add some on the{' '}
        <Link href="/admin">admin panel</Link> first.
      </ErrorMessage>
    )
  }

  const toHTML = (value: any) =>
    remark().use(remarkHTML).use(remarkGFM).processSync(value).toString()

  return (
    <PageLayout
      seo={{
        title: t('faq.title'),
        siteName: data.site.siteMetadata.title,
        lang: language,
      }}
      footerProps={{
        phoneNumber: data.localizedSettings?.frontmatter?.phoneNumber,
      }}
      subpage
    >
      <CookieConsentProvider
        language={language}
        title={t('cookie.consent.title')}
        body={t('cookie.consent.body')}
        acceptLabel={t('cookie.consent.agree')}
        rejectLabel={t('cookie.consent.decline')}
        linkLabel={t('cookie.consent.link')}
      />

      <Section>
        <Grid container direction="row">
          <SidebarGrid md={3} xs={12} />

          <Grid item md={9} xs={12}>
            <StyledPageTitle color="primary" variant="h2">
              {t('faq.title')}
            </StyledPageTitle>

            {data.faq?.frontmatter.categories.map((category) => (
              <StyledCategoryContainer key={category.title}>
                <StyledCategoryTitle variant="h3" color="primary">
                  {category.title}
                </StyledCategoryTitle>

                <Box>
                  {category.questions.map(({question, answer}) => (
                    <StyledAccordion key={question} elevation={0} square>
                      <StyledAccordionSummary expandIcon={<ExpandMore />}>
                        {question}
                      </StyledAccordionSummary>
                      <StyledAccordionDetails
                        dangerouslySetInnerHTML={{__html: toHTML(answer)}}
                      />
                    </StyledAccordion>
                  ))}
                </Box>
              </StyledCategoryContainer>
            ))}
          </Grid>
        </Grid>
      </Section>
    </PageLayout>
  )
}

export const query = graphql`
  query FAQPageQuery($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    faq: markdownRemark(
      frontmatter: {templateKey: {eq: "faq"}, language: {eq: $language}}
    ) {
      frontmatter {
        categories {
          title
          questions {
            question
            answer
          }
        }
      }
    }
    localizedSettings: markdownRemark(
      frontmatter: {
        templateKey: {eq: "localized-settings"}
        language: {eq: $language}
      }
    ) {
      frontmatter {
        phoneNumber
      }
    }
  }
`

export default FAQPage
